import siteMixin from "@/mixins/siteMixin";
import userMixin from "@/mixins/userMixin";
import permissionsMixin from "@/mixins/permissionsMixin";

export default {
    data: () => ({
        user: null,
        site: null,
        cms6Url: process.env.VUE_APP_JUNIPER_API,
        namespace: null,
        headers: {},
        methodPermissions: {
            getTicker: "view_posts",
            getAlerts: "view_alerts",
            getCategories: "view_post_categories",
        },
        controller: null,
    }),
    methods: {
        prepareForFetch(namespace) {
            this.user = this.getCurrentUser();
            if (!this.user || !this.user.auth) {
                return;
            }
            if (this.controller) {
                this.controller.abort();
            }
            this.controller = new AbortController();
            this.namespace = namespace || "/twd-dashboard/v1";
            this.site = this.getSite();

            // if (this.site && this.site.url) { this.site.url = this.site.url.replace('http://','https://') }
            // Bearer 1|tOERsn881EudV8NMJGpwK0a0WG1AEJifKU1eAwYe
            this.headers = { ...this.headers, ...{ Authorization: "Bearer " + this.user.auth } };
            // this.headers = { Authorization: 'Basic ' + this.user.auth };
        },
        async dashboardInfo(from, to, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + "/wp-json" + this.namespace + "/dashboard", {
                headers: this.headers,
                params: { from: from, to: to },
            });
        },
        async getPosts(page = 1, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + "/wp-json/wp/v2/posts?_embed=1&context=edit", {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: { page: page },
            });
        },
        async getPost(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/posts/${id}?_embed=1&context=edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async getNews(page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", filterDate = null, showTrashed, namespace) {
            // get post_type post and tw_alerts
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                status: "publish, future, draft, private",
                search: querySearch,
            };
            if (filterDate) {
                console.log(this.moment(filterDate).startOf("month").format("Y-MM-DDTHH:mm:ss"));
                console.log(this.moment(filterDate).endOf("month").format("Y-MM-DDTHH:mm:ss"));
                // params = {...params, ...{after: '2021-12-31T23:00:00'}}
                params = { ...params, ...{ after: this.moment(filterDate).startOf("month").format("Y-MM-DDTHH:mm:ss") } };
                // params = {...params, ...{before: '2022-01-31T22:59:59'}}
                params = { ...params, ...{ before: this.moment(filterDate).endOf("month").format("Y-MM-DDTHH:mm:ss") } };
                // 2019-10-03T13:00:00
            }

            let route = "news-notices";

            if (showTrashed) {
                route = "news-notices/trashed";
            }

            try {
                return await this.axios.get(this.cms6Url + route, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    params: params,
                    signal: this.controller.signal,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async savePost(post, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            // var formData = new FormData();
            // formData.append('categories', categories);
            // formData.append('title', title);
            // formData.append('content', content);
            // if (mediaId) {
            // 	formData.append('featured_media', mediaId);
            // }
            // formData.append('status', status);

            // console.log(title)
            // {categories: [4], status: "publish", featured_media: 8538, title: "test iz dash", content}
            //featured_media ->id
            return await this.axios.post(this.cms6Url + `posts`, post, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updatePost(post, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/posts/${post.id}`, post, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deletePost($id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.cms6Url + "posts/" + $id, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        /*====================================
		=            NEWS&NOTICES            =
		====================================*/
        async getNewsNoticesCategories(slug = null, allowed_terms = null, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                per_page: 100,
                hide_empty: 0,
            };
            if (slug) {
                params = { ...params, ...{ slug: slug } };
            }

            if (Array.isArray(allowed_terms)) {
                if (!allowed_terms.length) {
                    allowed_terms.push("");
                }
                params = { ...params, ...{ include: allowed_terms } };
            }
            return await this.axios.get(this.cms6Url + `news-notice-categories`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },
        async getNewsNotice(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.cms6Url + `news-notices/${id}/edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async saveNewsNotice(post, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.cms6Url + `news-notices`, post, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updateNewsNotice(post, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.put(this.cms6Url + `news-notices/${post.id}`, post, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deleteNewsNotice($id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.cms6Url + "news-notices/" + $id, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        async newsNoticeRestore(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            return await this.axios.post(
                this.cms6Url + `news-notices/${id}/restore`,
                {},
                {
                    // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                    headers: this.headers,
                }
            );
        },
        async newsNoticeSlugSave(id, slug) {
            return await this.axios.put(
                this.cms6Url + `news-notices/${id}/update-slug`,
                { slug: slug },
                {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                }
            );
        },
        async forceDeleteNewsNotice(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.cms6Url + `news-notices/${id}/delete`, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        /*=====  End of NEWS&NOTICES  ======*/
        /*==============================
		=            TICKER            =
		==============================*/
        async getTicker(categories = [], page = 1, perPage = 10, order = "desc", orderBy = "date", namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                status: "publish",
                categories: categories,
            };

            return await this.axios.get(this.cms6Url + "posts", {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },
        /*=====  End of TICKER  ======*/
        /*==============================
		=            ALERTS            =
		==============================*/
        async getAlerts(page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", filterDate = null, namespace) {
            // get post_type tw_alerts
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                type: "alert",
                search: querySearch,
            };
            if (filterDate) {
                // console.log(this.moment(filterDate).startOf('month').format('Y-MM-DDTHH:mm:ss'))
                // console.log(this.moment(filterDate).endOf('month').format('Y-MM-DDTHH:mm:ss'))
                // params = {...params, ...{after: '2021-12-31T23:00:00'}}
                params = { ...params, ...{ after: this.moment(filterDate).startOf("month").format("Y-MM-DDTHH:mm:ss") } };
                // params = {...params, ...{before: '2022-01-31T22:59:59'}}
                params = { ...params, ...{ before: this.moment(filterDate).endOf("month").format("Y-MM-DDTHH:mm:ss") } };
                // 2019-10-03T13:00:00
            }

            try {
                return await this.axios.get(this.cms6Url + `alerts`, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    params: params,
                    signal: this.controller.signal,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getAlert(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/tw_alerts/${id}?_embed=1&context=edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async saveAlert(alert, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.cms6Url + `alerts`, alert, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updateAlert(alert, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/tw_alerts/${alert.id}`, alert, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deleteAlert($id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.cms6Url + "alerts/" + $id, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        /*=====  End of ALERTS  ======*/

        /*=============================
		=            PAGES            =
		=============================*/
        async getPages(page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", showTrashed, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            let route = "pages";

            if (showTrashed) {
                route = "pages/trashed";
            }

            try {
                return await this.axios.get(this.cms6Url + route, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: {
                        page: page,
                        per_page: perPage,
                        order: order, // 'asc'
                        orderby: orderBy,
                        search: querySearch,
                        status: "any",
                    },
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getPage(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.cms6Url + `pages/${id}/edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async savePage(page, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.cms6Url + `pages`, page, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async savePageFromPageBuilder(page, namespace) {
            console.log("cms6 savePageFromPageBuilder");
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            return await this.axios.post(this.cms6Url + `pages`, page, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updatePageFromPageBuilder(page, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.put(this.cms6Url + `pages/${page.id}`, page, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updatePage(page, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.put(this.cms6Url + `pages/${page.id}`, page, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async getPagePreview(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.cms6Url + `pages/${id}/preview`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deletePage(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.cms6Url + "pages/" + id, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        async pageRestore(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            return await this.axios.post(
                this.cms6Url + `pages/${id}/restore`,
                {},
                {
                    // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                    headers: this.headers,
                }
            );
        },
        async slugSave(id, slug) {
            return await this.axios.put(
                this.cms6Url + `pages/${id}/update-slug`,
                { slug: slug },
                {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                }
            );
        },
        async forceDeletePage(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.cms6Url + `pages/${id}/delete`, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        /*=====  End of PAGES  ======*/

        // page: state.pagination.start / state.pagination.number + 1,
        //             per_page: state.pagination.number * 1,
        //             order: state.sort.reverse ? 'desc' : 'asc',
        //             orderby: state.sort.predicate,
        //             search: vm.querySearch,
        //             status: 'any',
        //             post_type: ['post','tw_alerts']

        async getMeetingRepositories(useParams = false, page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {};
            if (useParams) {
                // var params = {
                //         page: state.pagination.start / state.pagination.number + 1,
                //         per_page: state.pagination.number * 1,
                //         order: state.sort.reverse ? 'desc' : 'asc',
                //         'filter[meta_key]': 'meeting_date',
                //         'filter[orderby]': state.sort.predicate == 'date' ? 'meta_value' : state.sort.predicate,
                //         search: vm.querySearch
                //       };

                params = {
                    _embed: 1,
                    page: page,
                    per_page: perPage,
                    order: order, // 'asc'
                    // orderby: orderBy,
                    search: querySearch,
                    status: "any",
                };

                if (orderBy == "date") {
                    params = {
                        ...params,
                        ...{
                            // search: querySearch
                            "filter[meta_key]": "meeting_date",
                            "filter[orderby]": "meta_value",
                        },
                    };
                } else {
                    params = { ...params, ...{ orderby: orderBy } };
                }
            }

            try {
                return await this.axios.get(this.site.url + "/wp-json/wp/v2/twd_repository", {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    signal: this.controller.signal,
                    headers: this.headers,
                    params: params,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getMeetingRepositoryCategories(namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                per_page: 100,
                hide_empty: 0,
            };
            return await this.axios.get(this.cms6Url + `meeting-repository-categories`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },
        async getMeetingRepository(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                hide_empty: 0,
            };
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/twd_repository/${id}/?_embed=1&context=edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },
        async saveRepository(repository, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/twd_repository`, repository, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updateRepository(repository, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/twd_repository/${repository.id}`, repository, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deleteMeetingRepository(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.site.url + `/wp-json/wp/v2/twd_repository/${id}`, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        async meetingRepositoryNotify(id) {
            return await this.axios.post(
                this.site.url + `/wp-json/wp/v2/meet-repo-notify`,
                { repository_id: id },
                {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                }
            );
        },
        /*==============================
		=            EVENTS            =
		==============================*/
        async getEvents(page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", startDate, endDate, namespace) {
            // async getEvents(page = 1, perPage = 10, order = 'desc', orderBy = 'date', querySearch = '', filterDate = null, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            querySearch;
            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                // search: querySearch
                start_date: startDate,
                end_date: endDate,
            };
            if (querySearch) {
                params = { ...params, ...{ search: querySearch } };
            }
            //if (filterDate) {
            // console.log(filterDate)

            // 	console.log(this.moment(filterDate).startOf('month').format('Y-MM-DDTHH:mm:ss'))
            // 	console.log(this.moment(filterDate).endOf('month').format('Y-MM-DDTHH:mm:ss'))
            // params = {...params, ...{after: '2021-12-31T23:00:00'}}
            //params = {...params, ...{start_date: this.moment(filterDate).startOf('month').format('Y-MM-DDTHH:mm:ss')}}
            // 	// params = {...params, ...{before: '2022-01-31T22:59:59'}}
            //params = {...params, ...{end_date: this.moment(filterDate).endOf('month').format('Y-MM-DDTHH:mm:ss')}}
            // 	// 2019-10-03T13:00:00
            //}

            try {
                return await this.axios.get(this.site.url + `/wp-json/tribe/events/v1/events`, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: params,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getEvent(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + `/wp-json/tribe/events/v1/events/${id}?_embed=1&context=edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async saveEvent(event, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            return await this.axios.post(this.site.url + `/wp-json/tribe/events/v1/events`, event, {
                headers: this.headers,
            });
        },
        async updateEvent(event, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/tribe/events/v1/events/${event.id}`, event, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deleteEvent(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.site.url + `/wp-json/tribe/events/v1/events/${id}`, {
                headers: this.headers,
            });
        },

        async getEventCategories(slug = null, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                per_page: 100,
                hide_empty: 0,
            };
            if (slug) {
                params = { ...params, ...{ slug: slug } };
            }
            return await this.axios.get(this.site.url + `/wp-json/tribe/events/v1/categories`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },
        /*=====  End of EVENTS  ======*/
        /*===================================
		=            DEPARTMENTS            =
		===================================*/
        async getDepartments(category = null, querySearch = "", page = 1, perPage = 100, order = "asc", orderBy = "title", namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            querySearch;
            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                search: querySearch,
                status: "any",
            };

            if (category) {
                params = { ...params, ...{ departments_categories: category } };
            }

            try {
                return await this.axios.get(this.cms6Url + `departments`, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: params,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getDepartmentsSimple() {
            try {
                return await this.axios.get(this.cms6Url + `departments-simple`, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getDepartmentBySlug(slug, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                slug: slug,
                status: "any",
            };
            return await this.axios.get(this.cms6Url + `departments`, {
                headers: this.headers,
                params: params,
            });
        },
        async saveDepartment(department, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.cms6Url + `departments`, department, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updateDepartment(department, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.put(this.cms6Url + `departments/${department.id}`, department, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async getDepartmentsCategories(namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                per_page: 100,
                hide_empty: 0,
            };
            return await this.axios.get(this.cms6Url + `department-categories`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },
        async deleteDepartment($id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.cms6Url + "departments/" + $id, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        /*=====  End of DEPARTMENTS  ======*/
        /*============================
        =            FAQS            =
        ============================*/
        async getFaqs(page = 1, perPage = 100, order = "asc", orderBy = "title", querySearch = "", namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            querySearch;
            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                search: querySearch,
                status: "any",
            };

            try {
                return await this.axios.get(this.cms6Url + `faqs`, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: params,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getFaq(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.cms6Url + `faqs/${id}/edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async saveFaq(faq, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.cms6Url + `faqs`, faq, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updateFaq(faq, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.put(this.cms6Url + `faqs/${faq.id}`, faq, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deleteFaq($id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.cms6Url + "faqs/" + $id, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        async getFaqCategories(slug = null, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                per_page: 100,
                hide_empty: 0,
            };
            if (slug) {
                params = { ...params, ...{ slug: slug } };
            }
            return await this.axios.get(this.cms6Url + `faq-categories`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },

        /*=====  End of FAQS  ======*/

        /*===================================
        =            SUBSCRIBERS            =
        ===================================*/
        async getSubscribers(page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            let params = {
                _embed: 1,
                page: page,
                per_page: perPage,
                order: order,
                orderby: orderBy,
                search: querySearch,
                status: "any",
            };

            try {
                return await this.axios.get(this.cms6Url + `subscribers`, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: params,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getSubscriber(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            // let params = {
            //     'hide_empty': 0
            // };
            return await this.axios.get(this.cms6Url + `subscribers/${id}/edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                // params: params
            });
        },
        async saveSubscriber(subscriber, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.cms6Url + `subscribers`, subscriber, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updateSubscriber(subscriber, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.put(this.cms6Url + `subscribers/${subscriber.id}`, subscriber, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deleteSubscriber(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.cms6Url + `subscribers/${id}`, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        async downloadSubscribersList(namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.cms6Url + `subscribers/download`, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                responseType: "blob",
                headers: this.headers,
            });
        },
        async getAllowedTerms(namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.cms6Url + `subscribers/allowed_terms`, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        /*=====  End of SUBSCRIBERS  ======*/

        async sendTicket(subject, message, namespace) {
            this.prepareForFetch(namespace);
            let user = this.getCurrentUser();
            if (!user) return;

            return await this.axios.post(
                this.cms6Url + "support-ticket",
                {
                    subject: subject,
                    message: message,
                    site: this.getSite(),
                },
                {
                    headers: this.headers,
                }
            );
        },
        async getStats(from, to, query, namespace) {
            this.prepareForFetch(namespace);
            return await this.axios.get(this.site.url + "/wp-json" + this.namespace + "/site-stats", {
                headers: this.headers,
                params: { from: from, to: to, query: query },
            });
        },
        /*===================================
        =            FILE UPLOAD            =
        ===================================*/
        async uploadFile(file, image = false, namespace) {
            var formData = new FormData();
            formData.append("file", file);

            if (image) {
                formData.append("image", true);
            }

            this.prepareForFetch(namespace);
            return await this.axios.post(this.cms6Url + "media", formData, {
                headers: { ...this.headers, ...{ "Content-Type": "multipart/form-data" } },
            });
        },
        /*=====  End of FILE UPLOAD  ======*/
        /*=====================================
        =            MEDIA LIBRARY            =
        =====================================*/
        async getMediaLibrary(page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", onlyImages = false, fileType = false, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                search: querySearch,
            };
            if (onlyImages && !fileType) {
                params = { ...params, ...{ media_type: "image" } };
            }
            if (fileType) {
                params = { ...params, ...{ media_type: fileType } };
            }
            try {
                return await this.axios.get(this.cms6Url + `media`, {
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: params,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getMedia(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/media/${id}`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deleteMedia($id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.cms6Url + `media/${$id}`, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
                // params: { force: true },
            });
        },
        /*=====  End of MEDIA LIBRARY  ======*/
        async getCategories(slug = null, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                per_page: 100,
                hide_empty: 0,
            };
            if (slug) {
                params = { ...params, ...{ slug: slug } };
            }

            return await this.axios.post(this.cms6Url + `post-categories`, params, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        /*=================================
		=            TEMPLATES            =
		=================================*/
        async getTemplates(namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.cms6Url + `clients/${this.site.id}/page-templates`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        /*=====  End of TEMPLATES  ======*/
        /*=============================
        =            MENUS            =
        =============================*/
        async getMenus(namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.cms6Url + `menus/${this.site.id}`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        /*=====  End of MENUS  ======*/
        /*===== FORM BUILDER ELEMENT FROM OPTION ========*/
        async getClientOptionKey(optionKey, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.cms6Url + `options/${this.site.id}/${optionKey}`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        /*=====  End of FORM BUILDER ELEMENT FROM OPTION  ======*/

        /*===== FORM BUILDER ELEMENT FROM OPTION ========*/
        async getThemeSettings(theme, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.cms6Url + `themes/${theme}/${this.site.id}/theme-settings`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        /*=====  End of FORM BUILDER ELEMENT FROM OPTION  ======*/

        // Function Parse Page Content
        async parsePageContent(page) {
            return await this.axios.post(this.cms6Url + `page-builder/parse/page`, page, {
                headers: this.headers,
            });
        },

        // Function Get Theme CSS
        async getThemeCss(theme, page) {
            return await this.axios.post(this.cms6Url + "themes/" + theme + "/css", page, {
                headers: this.headers,
            });
        },

        // Function Get Theme CSS
        async getRevisions(object, subjectType) {
            let data = {
                subject_id: object.id,
                subject_type: subjectType,
            };
            return await this.axios.post(this.cms6Url + "revisions/get", data, {
                headers: this.headers,
            });
        },
    },
    mixins: [userMixin, siteMixin, permissionsMixin],
};
